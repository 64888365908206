import React from 'react';
import Scrollspy from 'react-scrollspy';


const FooterSeven = () => {
  return (
    <div className="row ">
      <div
        className="col-xl-3 col-lg-2 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="logo">
          <a href="/">
            <img src="images/logo/teachme-logo.svg" alt="logo" />
          </a>
        </div>
        <div>We serve almost globally.
        </div>
      </div>
      {/* End .col */}

      <div
        className="col-lg-2 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="title">Links</h5>
        <Scrollspy className="footer-list" 
          items={[
             "home",
             "about",
            ]}
          currentClassName="active"
          offset={-90}>
          <li >
            <a href="#home">Home</a></li>
          <li >
            <a href="#about">About Us</a></li>
        </Scrollspy>
      </div>

      <div
        className="col-lg-3 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="title">Legal</h5>
        <Scrollspy className="footer-list"
                items={[
                  "terms",
                  "privacy",
                 ]}
                 currentClassName="active"
                 offset={-90}>

          <li>
            <a href="#terms">Terms & conditions</a>
          </li>
          <li>
            <a href="#privacy">Privacy policy</a>
          </li>
        </Scrollspy>
      </div>
      <div
        className="col-xl-4 col-lg-5 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >

          <h5 className="title">Join Now</h5>
          <p>
            Sign up over <span>1,000</span> people, 
          </p>
          {/* <FormFooterSignup /> */}
          <div>
            We are waiting for you to see you on TeachMe Platform.
          </div>

        {/* /.newsletter */}
      </div>
    </div>
  );
};

export default FooterSeven;
