import React from 'react';
import ScrollTopBehaviour from '../components/inc/ScrollTopBehaviour';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


// All HOME PAGE ROUTES
import Home from '../components/pages/Home';


// Not Found Page
import NotFound from '../components/views/NotFound';


const Routers = () => {
  return (
      <Router>
        <ScrollTopBehaviour />

        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </Router>

  );
};

export default Routers;