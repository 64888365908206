import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Routes from './router/Routes';
import ScrollToTop from './components/inc/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>TeachMe - Marketing and communication platform.</title>
        <meta property="og:site_name" content="TeachMe" />
        <meta
          property="og:url"
          content="https://github.com/devomman/teachme-web"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="TeachMe - Marketing and communication platform."
        />
        <meta
          name="keywords"
          content="online marketing, marketing, communication, educational organizations, training institutes, private tutors"
        />
        <meta
          name="description"
          content="TeachMe - Marketing and communication platform."
        />
        <meta name="description" content="Marketing and communication platform." />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;