import React from 'react';
// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Navbar Header
import HeaderLandingMobileApp from '../inc/Navbar';

import FaqFour from '../inc/faq/FaqFour';
import MobileAppPortfolio from '../inc/portfolio/MobileAppPortfolio';
import FooterSeven from "../inc/footer/FooterSeven";
import CopyRightThree from "../inc/footer/CopyRightThree";
import FancyFeatureTewentyThree from "../inc/features/FancyFeatureTewentyThree";

const Home = () => {
return (
<div className="main-page-wrapper font-gordita">
  <Helmet>
    <title>
      TeachMe Mobile App | Download TeachMe iOS and Android App Now
    </title>
  </Helmet>
  {/* End Page SEO Content */}

  <HeaderLandingMobileApp />
  {/* End Mobile App Landing Header */}

  {/* =============================================
  Theme Hero Banner
  ============================================== */}
  <div className="hero-banner-ten" id="home">
    <div className="container">
      <div className="row">
        <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
          <h1 className="hero-heading" data-aos="fade-up" data-aos-duration="1200">
            Expert Tutor's on TeachMe Platform.
          </h1>
          <p className="hero-sub-heading" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
            Whether you want to learn or to share what you know, you’ve come to the right place.

          </p>
        </div>
      </div>
      {/* End .row */}

      <div className="d-sm-flex align-items-center justify-content-center button-group">
        <a href="/" className="d-flex align-items-center ios-button" data-aos="fade-up" data-aos-duration="1200"
          data-aos-delay="200">
          <img src="images/icon/apple.svg" alt="icon" className="icon" />
          <div>
            <span>Download on the</span>
            <strong>App store</strong>
          </div>
        </a>
        <a href="/" className="d-flex align-items-center windows-button" data-aos="fade-up" data-aos-duration="1200"
          data-aos-delay="350">
          <img src="images/icon/playstore.svg" alt="icon" className="icon" />
          <div>
            <span>Get it on</span>
            <strong>Google play</strong>
          </div>
        </a>
      </div>
    </div>
    {/* End .container */}

    <div className="icon-box-one">
      <img src="images/logo/logo-39.png" alt="icon" />
    </div>
    <div className="icon-box-two">
      <img src="images/logo/logo-40.png" alt="icon" />
    </div>
    <div className="icon-box-three">
      <img src="images/logo/logo-41.png" alt="icon" />
    </div>
    <div className="icon-box-four">
      <img src="images/logo/logo-42.png" alt="icon" />
    </div>
    <div className="icon-box-five">
      <img src="images/logo/logo-43.png" alt="icon" />
    </div>
    <div className="icon-box-six">
      <img src="images/logo/logo-44.png" alt="icon" />
    </div>
    <div className="icon-box-seven">
      <img src="images/logo/logo-45.png" alt="icon" />
    </div>
    <div className="icon-box-eight">
      <img src="images/logo/logo-46.png" alt="icon" />
    </div>
  </div>
  {/* /.hero-banner-ten */}

  {/* =============================================
  App Screen Preview
  ============================================== */}
  <div className="app-screen-preview-one">
    <div className="container">
      <div className="app-preview-slider-one arrow-middle-center ">
        <MobileAppPortfolio />
      </div>
    </div>
    {/* End .container */}

    <img src="images/shape/bg-tm-02.svg" alt="shape" className="shapes round-bg" />
    <img src="images/shape/216.svg" alt="shape" className="shapes shape-one" />
    <img src="images/shape/217.svg" alt="shape" className="shapes shape-two" />
    <img src="images/shape/218.svg" alt="shape" className="shapes shape-three" />
    <img src="images/shape/219.svg" alt="shape" className="shapes shape-four" />
  </div>
  {/* /.app-screen-preview-one */}

  {/* =============================================
  Fancy Feature Twenty Two
  ============================================== */}
  <div className="fancy-feature-twentyTwo mpt-200 pt-130 md-mt-80 sm-pt-100" id="about">
    <div className="container">
      <div className="mb-40 md-mb-20"><h3>About TeachMe?</h3>
      <p >TeachMe Platform is an online marketing and communication platform for educational
          organizations, training institutes, and private tutors. The Platform could be accessed by downloading the
          application from Apple and Android stores. It also can be accessed by the responsive web application in
          TeachMe website.
        </p>
      </div>

      <div className="mb-40 md-mb-20"><h3>How does it work?</h3>
      <p>TeachMe TeachMe allow students to search for tutors and communicate with them by
          clicking on search options and using the in-app filters for search. Once the tutor is found and his/her
          profile is matching the requirements of the student, only the student can initiate the communication by
          requesting to chat with the tutor or ask him/her directly for their contact details. The communication between
          the tutor and the student is not supervised by TeachMe. TeachMe does not charge the student any amount for
          communicating with the tutors.
        </p>
      </div>

      <div className="row justify-content-center">
        <FancyFeatureTewentyThree />
      </div>
    </div>
  </div>
  {/* /.fancy-feature-twentyTwo */}

  {/* =============================================
  Fancy Feature Twenty Five
  ============================================== */}

  <div className="fancy-feature-twentyFive lg-container pt-50 md-pt-100" id="benefits">
    <div className="container">
      <div className="block-style-twentyFive">
      <div>
              <h6>
                TeachMe Tutor Benefits
              </h6>
              <h3 className="title">How can I become a tutor?</h3>
              <p>You can become a tutor by clicking on becoming a tutor icon and then follow the instructions from there.</p>
        </div>


        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 col-md-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
            <div className="screen-container">
              <img src="images/assets/become-tutor-s1.jpg" alt="screen" />
            </div>
            {/* /.screen-container */}
          </div>
          <div className="col-xl-5 col-lg-6" data-aos="fade-left" data-aos-duration="1200">
            <div className="text-wrapper pl-xl-5">
            <h5 className="title">How can I become a verified tutor?</h5>
            <p>You can become a verified tutor by clicking on verified tutor icon and then follow the instructions from there.</p>
            </div>
            {/* /.text-wrapper */}
          </div>
        </div>

        <div>
              {/* <h6>
                TeachMe Tutor Benefits
              </h6> */}
              <h3 className="title">What can the verified tutor option add to my profile?</h3>
              <p>It will enable TeachMe to verify your account and inform students that you are a qualified tutor.</p>
        </div>
      </div>
      {/* /.block-style-twentyFive */}

      <div className="block-style-twentyFive mt-50 md-mt-100">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 col-md-10 m-auto text-center text-lg-right order-lg-last"
            data-aos="fade-left" data-aos-duration="1200">
            <div className="screen-container">
              <img src="images/shape/bg-tm-01.svg" alt="shape" />

              {/* /.block-content */}
            </div>
            {/* /.screen-container */}
          </div>
          <div className="col-xl-5 col-lg-6" data-aos="fade-right" data-aos-duration="1200">
            <div className="text-wrapper order-lg-first">
              <h6>
                Subject Benefits
              </h6>
              <h3 className="title">How do I choose my subjects?</h3>
              <p>TeachMe allows the tutors to post in any subjects they want within their field of experience. However, TeachMe provides each tutor with maximum of key words (subjects names). Additional subjects and keywords are subject to additional payment. </p>
            </div>
            {/* /.text-wrapper */}
          </div>
        </div>
      </div>
      {/* /.block-style-twentyFive */}
    </div>
  </div>
  {/* /.fancy-feature-twentyFive */}

 {/* START FAQ */}
  <div className="faq-section-four mt-50 md-mt-100" id="faq">
        <img
          src="images/shape/120.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/121.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="container">
          <div className="title-style-five text-center mb-80 md-mb-60">
            <h6>FAQ</h6>
            <h2>
              <span>Frequently Asked Question</span>
            </h2>
          </div>

          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <FaqFour />
            </div>
          </div>
          {/* <div
            className="text-center mt-60 md-mt-50"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h3 className="font-rubik pb-30">Don’t find your answer?</h3>
            <Link className="theme-btn-five" to="/contact-eo">
              Contact us
            </Link>
          </div> */}
        </div>
        {/* /.container */}
      </div>

       {/* End FAQ */}



{/* Strat Terms */}
  <div className="terms_and_policy" id="terms">
    <div className="container">

            {/* Tab panes */}
            <div className="tab-content ">
              <div>
                <h2 className="font-gilroy-bold">Terms and Definitions</h2>
                <div className="update-date">LAST UPDATED: 25/12/2022</div>
                <h3>TeachMe Platform:</h3>
                <p className="text-justify">
                  TeachMe Platform is an online marketing and communication platform for educational organizations,
                  training institutes, and private tutors. The Platform could be accessed by downloading the application
                  from Apple and Android stores. It also can be accessed by the responsive web application in TeachMe
                  website http://teachme.qa.
                </p>

                <h3>Tutor:</h3>
                <p className="text-justify">
                  This term apply to registered tutors who would provide their services to potential students in their
                  respective subjects. Tutors can be verified or non-verified based on their own desire. However,
                  unverified tutors will be clearly advertised as unverified and TeachMe Platform cannot ensure their
                  experience in the field. Therefore, TeachMe encourages its students to deal only with verified tutors.
                  Verified tutors are those who have presented their academic and professional qualifications along with
                  their official verification documents to be scanned and approved by TeachMe Platform Management Team.
                </p>
                <h3>Educational Organization:</h3>
                <p className="text-justify">This term apply to all licensed education organizations in any part of the world.</p>
                <h3>Training Institutes:</h3>
                <p className="text-justify">This term apply to all licensed training institutes in any part of the world.</p>
                <h3>Student:</h3>
                <p className="text-justify">This term apply to all students (either registered in TeachMe Platform or not) who wish to use the
                  services of the platform either through online courses, tutors’ communication, and library. For
                  communicating with tutors, students will not have to pay any amount to TeachMe Platform. The platform
                  mission is to connect students with experienced tutors for “FREE”.</p>
                <h3>Courses:</h3>
                <p className="text-justify">This term apply to the section of TeachMe Platform in which students can enrol in online courses for
                  certain amount of money that is published and agreed upon separately for each course.</p>
                <h3>Library:</h3>
                <p className="text-justify">This term apply to the section of TeachMe Platform in which students can buy reading materials of
                  their choice to be viewed within the application. </p>

              </div>
              <div>
                <h2 className="font-gilroy-bold">Scope of the Services</h2>

                <p className="text-justify">TeachMe is an online marketing platform for education organizations, training institutes and tutors.
                  The aim of this platform is to solve the issue of finding a tutor for any subject in any part of the
                  world. TeachMe Platform does not provide or organize tutoring sessions. The mission of the application
                  is to simply enable the student to connect “freely” with the tutors and chose how to receive the
                  tutoring services.<br />
                  In case of online courses, TeachMe will facilitate in-app Zoom Integration in which tutors can provide
                  online sessions to students who enrol in the advertised course for a certain amount of money. TeachMe
                  facilitates the payment and the completion of the courses. <br />
                  Tutors shall comply with the applicable laws of their countries, TeachMe shall not be held liable or
                  responsible for any actions by Tutors, including but not limited to; any tutoring sessions, services,
                  or information shared by a Tutor.
                </p>


              </div>
              <div>
                <h2 className="font-gilroy-bold">General Terms</h2>
                <p className="text-justify">Using, browsing, surfing, or accessing the platform is considered consent to these Terms and
                  Conditions, thus these Terms and Conditions shall be considered as a binding agreement between TeachMe
                  and Visitors.<br />
                  All users are not allowed to use this platform in an inappropriate, illegal, offensive or unauthorized
                  manner. This ban includes but is not limited to commercial ads, inappropriate language, etc.<br />
                  TeachMe reserves the right to alter or change, amend these Terms and Conditions the way they see fit
                  at any time. A continued usage of the platform by the Visitor post amendments of the Terms and
                  Conditions is considered approval of these changes.<br />
                  TeachMe shall notify Students and Tutors of any changes to the Terms and Conditions through the email
                  address that was provided by the Student in the registration form. A continued usage of the platform
                  by the Student post amendments of the Terms and Conditions is considered approval of these changes.<br />
                  Students agree to use TeachMe at his/her own risk, by using TeachMe, the Student agrees to be bound by
                  its Terms and Conditions and to indemnify and keep indemnified Teachme, its owners and management team
                  against any claim, suits, and damages with regards to any activity related to their use of the
                  platform by them or any other person accessing the platform using their membership account.<br />
                </p>
              </div>
              <div>
                <h2 className="font-gilroy-bold">Registration</h2>
                <p className="text-justify">
                  For a Visitor to become a registered user (Student), he/she has to fill the registration form online,
                  which requires the following: <br />
                  - Name.<br />
                  - Email address.<br />
                  - Grade/Interest (Optional).<br />
                  - Mobile number (Optional).<br />
                  - Password.<br />
                  All Students who register in this platform must be of 21 years old or older. In case the Student is younger, the account and all bookings, actions, and activities shall be done through a parent. The following must be noted: <br />
                  -	Every student shall have an account with TeachMe with a distinctive username and password. Students can create their own username and password and can alter it at any time. <br />
                  -	TeachMe reserves the right to approve the username and password chosen by the Student. Students shall read and agree to the privacy policy adopted by TeachMe. <br />
                  -	Students shall be responsible for all activities made through their accounts including but not limited to, requesting a session, slandering, posting an offensive comment, or publishing inappropriate materials. <br />
                  -	Every Student is allowed to have only one account on this platform; multiple accounts are prohibited. <br />
                  -	Students have to keep their usernames and passwords confidential and not to share with a third party to protect their privacy. <br />
                  For a Visitor to become a registered user (Tutor), he/she has to fill the registration form online, which requires the following:<br />
                  -	Name.<br />
                  -	Email address.<br />
                  -	Mobile Number.<br />
                  -	Educational Certificates.<br />
                  -	Professional Certificates.<br />
                  -	Personal Photo.<br />
                  -	Password.<br />
                  All Tutors who register in this platform must be of 21 years old or older. The following must be noted:<br />
                  -	Every Tutor shall have an account with TeachMe with a distinctive username and password. Tutors can create their own username and password and can alter it at any time. <br />
                  -	TeachMe reserves the right to approve the username and password chosen by the Tutor. Tutors shall read and agree to the privacy policy adopted by TeachMe. <br />
                  -	Tutors shall be responsible for all activities made through their accounts including but not limited to, offering a session, slandering, posting an offensive comment, or publishing inappropriate materials. <br />
                  -	Every tutor is allowed to have only one account on this platform; multiple accounts are prohibited. <br />
                  -	Tutors have to keep their usernames and passwords confidential and not to share with a third party to protect their privacy.<br />
                  -	Tutors names will appear to students based on their ratings. Tutors are rated based on the feedback of the students after being in contact with the tutor. <br />
                  -	In order to become a verified tutor, the tutor needs to communicate with TeachMe management and fulfil all legal requirements such as official documents and a proof of experience and academic qualifications. <br />
                  -	In order to initiate a course, the tutor MUST be verified tutor by TeachMe Management. Unverified tutors cannot initiate courses. However, they can still post their services to students as private tutors in their respective fields and subjects. <br />
                  -	Verified tutors are not allowed to claim the knowledge of teaching in fields that does not match their qualifications and professional experience. <br />
                  Authority of TeachMe:<br />
                  -	TeachMe has the right, at its sole discretion, to terminate the services at any time. <br />
                  -	TeachMe have the right to record all courses sessions. Students are allowed to revisit the courses they had. <br />
                  -	When a student or a tutor deletes content in the chat or anywhere in the platform, it is deleted in a manner similar to emptying the recycle bin on a computer. TeachMe furthermore has the right to back up the deleted content for a reasonable time period. <br />
                  -	Students recognize and acknowledge the intellectual property rights of TeachMe on all content of the platform including but not limited to logos, trademarks, design, graphics, software, documents. All users are prohibited to use TeachMe name, logo, trademark, design, graphics, software, documents, or any other distinctive feature. <br />
                  -	Teachme reserves the right to terminate the account of any Student or Tutor that violate these Terms and Condition. <br />
                </p>
              </div>
              <div>
                <h2 className="font-gilroy-bold">Fees and Payment</h2>
                <p className="text-justify">
                -	Students do not pay any amount of money for registration and for searching and communicating with tutors. <br />
                -	Students pay only in case they purchase a spot in a course or purchased a reading material from the library. <br />
                -	The tutors pay the amount of registration based on the package they purchase. <br />
                -	The fees of a private session with a tutor are to be decided by the tutor and the student. <br />
                -	The fees of courses and reading materials are published with the announcement of each course and reading material. <br />
                -	TeachMe reserves its right to change offered packages (price, expiry of mins, etc.) at various markets at any time. Moreover, TeachMe reserves the right to offer promotional offers for limited period of time. <br />
                -	Tutors and Student may fund their accounts through available payment gateways. <br />
                </p>
              </div>
              <div>
                <h2 className="font-gilroy-bold">Refund policy</h2>
                <p className="text-justify">
                -	For Tutors’ registration, TeachMe has no cash refund policy. <br />
                -	For students who enrol in courses, TeachMe has no cash refund policy unless the course is being cancelled by TeachMe or by the Tutor. <br />
                -	For students who buy reading materials, TeachMe has no cash refund policy. <br />
                In case of an event where the student or the tutor wants to claim a refund, student shall send an email to support@teachme.qa
               </p>
              </div>
              <div>
                <h2 className="font-gilroy-bold">Governing Law and Venue</h2>
                <p className="text-justify">
                These Terms and Conditions shall be governed and constructed in accordance with the Qatari Law and general humanitarian ethics and international law. In case of any disputes, legal proceeding, litigation arising from or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of Qatari Law Organizations. 
               </p>
              </div>

            </div>
            {/* /.tab-content */}

    </div>
  </div>
{/* End Terms */}


{/* Strat Policy */}
<div className="terms_and_policy" id="privacy">
    <div className="container">

            {/* Tab panes */}
            <div className="tab-content ">
                <h2 className="font-gilroy-bold">Privacy Policy</h2>
                <div className="update-date">LAST UPDATED: 25/12/2022</div>
                <p className="text-justify">
                TeachMe Platform is an online marketing and communication platform for educational organizations, training institutes, and private tutors. The Platform could be accessed by downloading the application from Apple and Android stores. It also can be accessed by the responsive web application in TeachMe website http://teachme.qa
                </p>

                <h4>Information and Usage:</h4>
                <p className="text-justify">
                -	Only registered users have full access to the services and benefits offered by the platform. In order to register, you must provide us with some personal information that enables us to communicate with you. A mandatory online application must be filled with the information mentioned in the terms and conditions. <br />
                -	Students’ Names does not need to be displayed in the application. <br />
                -	Tutors names should always be displayed in the application. <br />
                -	Darisni Platform will send an email to the registered email address. The email message will include a verification code that you must submit to complete the registration process. <br />
                -	In case you want to amend your personal information or delete from TeachMe databases, you shall email us at support@teachme.qa <br />
                -	TeachMe shall not share your information with a third party without your permission. <br />
                </p>
               
            </div>
            {/* /.tab-content */}

    </div>
  </div>
{/* End Policy */}



  {/* =====================================================
  Fancy Short Banner Twelve
  ===================================================== */}
  <div className="fancy-short-banner-twelve pt-50 md-pt-100" id="download">
    <div className="container">
      <div className="row">
        <div className="col-xl-10 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
          <div className="title-style-ten text-center">
            <h2>Love Our TeachMe Application? Download Now!</h2>
            <p className="pt-25 pb-45">
              Try it Now — It's Free! We are always improve our App.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
        <div className="d-sm-flex align-items-center justify-content-center button-group">
          <a href="/" className="d-flex align-items-center ios-button">
            <img src="images/icon/apple-black.svg" alt="icon" className="icon" />
            <div>
              <span>Download on the</span>
              <strong>App store</strong>
            </div>
          </a>
          <a href="/" className="d-flex align-items-center ios-button">
            <img src="images/icon/playstore.svg" alt="icon" className="icon" />
            <div>
              <span>Get it on</span>
              <strong>Google play</strong>
            </div>
          </a>
        </div>
      </div>
    </div>
    {/* /.container */}
    <img src="images/shape/220.svg" alt="shape" className="shapes shape-one" />
    <img src="images/shape/221.svg" alt="shape" className="shapes shape-two" />
  </div>
  {/* /.fancy-short-banner-twelve */}

  {/* =====================================================
  Footer Style Seven
  ===================================================== */}
  <footer className="theme-footer-seven mt-120 md-mt-100">
    <div className="lg-container">
      <div className="container inner-btn-black">
        <FooterSeven />
      </div>

      <div className="container">
        <div className="bottom-footer">
          <CopyRightThree />
        </div>
      </div>
    </div>
    {/* /.lg-container */}
  </footer>
  {/* /.theme-footer-seven */}
</div>
);
};

export default Home;
