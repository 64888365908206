import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is TeachMe?",
    desc: `TeachMe Platform is an online marketing and communication platform for educational organizations, training institutes, and private tutors. The Platform could be accessed by downloading the application from Apple and Android stores. It also can be accessed by the responsive web application in TeachMe website http://teachme.qa`,
    expand: "a",
  },
  {
    title: "How does it work?",
    desc: `TeachMe allow students to search for tutors and communicate with them by clicking on search options and using the in-app filters for search. Once the tutor is found and his/her profile is matching the requirements of the student, only the student can initiate the communication by requesting to chat with the tutor or ask him/her directly for their contact details. The communication between the tutor and the student is not supervised by TeachMe. TeachMe does not charge the student any amount for communicating with the tutors.`,
    expand: "b",
  },
  {
    title: "How can I become a tutor?",
    desc: `You can become a tutor by clicking on becoming a tutor icon and then follow the instructions from there.`,
    expand: "c",
  },
  {
    title: "How can I become a verified tutor?",
    desc: `You can become a verified tutor by clicking on verified tutor icon and then follow the instructions from there.`,
    expand: "d",
  },
  {
    title: "How do I choose my subjects?",
    desc: `TeachMe allows the tutors to post in any subjects they want within their field of experience. However, TeachMe provides each tutor with maximum of 3 key words (subjects’ names). Additional subjects and keywords are subject to additional payment.`,
    expand: "e",
  },
  {
    title: "How can I initiate a course?",
    desc: `You can initiate a course only if you are verified tutor. You can initiate it by going to courses, initiate course icon and then follow the instructions from there.  `,
    expand: "f",
  },
  {
    title: "How can I join a course?",
    desc: `You can click on the course options. You will find a join notification and icon in the bottom of the page, by clicking that bottom; it will lead you to payment portal in which you can register for the course.`,
    expand: "g",
  },
  {
    title: "How can I search for tutors?",
    desc: `You can search for tutors by name or by subject.`,
    expand: "h",
  },
  {
    title: "How can I search for reading materials?",
    desc: `You can search for any reading materials by typing relevant search words in the search box. `,
    expand: "i",
  },
  {
    title: "How do I communicate with tutors?",
    desc: `You have the freedom ‘as a student’ to communicate with the tutor by phone, WhatsApp, or through the in-app chat option.`,
    expand: "j",
  },
  {
    title: "How would I know the tutor is experienced?",
    desc: `TeachMe can only promote and encourage the dealing with verified tutors. Verified tutors have their documents scanned and therefore, their qualifications match their claims and subjects.`,
    expand: "k",
  },
  {
    title: "How do I agree on the rate of the tutor services?",
    desc: `It is up to the student to agree or disagree with the tutor on the price suggested for the educational services provided. `,
    expand: "l",
  },
];

const FaqFour = () => {
  return (
    <div
      className="accordion-style-four"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="faq-wrraper">
        <Accordion preExpanded={["b"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqFour;
