import React from "react";
import Scrollspy from 'react-scrollspy';

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-instagram",
    link: "https://instagram.com/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/",
  },
  {
    icon: "fa-whatsapp",
    link: "https://web.whatsapp.com/",
  },
];

const CopyRightThree = () => {
  return (
    <div className="row">
      <div className="col-lg-4 order-lg-1 mb-20">

        <Scrollspy
          className="footer-list 
          d-flex 
          justify-content-center 
          justify-content-lg-start 
          footer-nav"                
          items={[
            "terms",
            "privacy",
           ]}
           currentClassName="active"
           offset={-90}>
          <li>
            <a href="#privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="#terms">Terms & Conditions</a>
          </li>
        </Scrollspy>
      </div>
      <div className="col-lg-4 order-lg-3 mb-20">
        <ul
          className=" d-flex
              justify-content-center justify-content-lg-end
              social-icon"
        >
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
        {/* End .social-icon */}
      </div>
      <div className="col-lg-4 order-lg-2 mb-20">
        <p className="copyright text-center">
        © Copyright @ {new Date().getFullYear()}{" "}
          <a
            href="https://multimedia.qa"
            target="_blank"
            rel="noopener noreferrer"
          >
            Multimedia
          </a>{" "}
          Qatar
        </p>
      </div>
    </div>
  );
};

export default CopyRightThree;
