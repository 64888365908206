import React, { useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { Link } from 'react-router-dom';

import LanguageSwitcher from './LanguageSwitcher.jsx';

const HeaderLandingMobileApp = () => {
  // For header select menu
  const [click1, setClick1] = useState(false);
  const handleClick1 = () => setClick1(!click1);

  // For Mobile  menu
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-five fixed"
            : "theme-main-menu sticky-menu theme-menu-five"
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <img src="images/logo/teachme-logo.svg" alt="brand" />
            </Link>
          </div>
          {/* End logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      "home",
                      "about",
                      "benefits",
                      "faq",
                      "download",
                    ]}
                    currentClassName="active"
                    offset={-90}
                  >
                    <li className="nav-item">
                      <a href="#home" className="nav-link">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#about" className="nav-link">
                        About Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#benefits" className="nav-link">
                        Benefits
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#faq" className="nav-link">
                        FAQ
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#download" className="nav-link">
                        Download
                      </a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          {/* End Navbar */}

          <div className="right-widget">
            <ul className="d-flex align-items-center">
              {/* <div className="mr-4">
                <LanguageSwitcher />
              </div> */}
              <li>
                <div
                  className={
                    click1
                      ? "dropdown download-btn ml-0 style-two show"
                      : "dropdown download-btn ml-0 style-two"
                  }
                >
                  <button
                    className="dropdown-toggle"
                    onClick={handleClick1}
                    type="button"
                  >
                    Download
                  </button>
                  <div
                    className={click1 ? "dropdown-menu  show" : "dropdown-menu"}
                  >
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="/"
                      onClick={handleClick1}
                    >
                      <img src="images/icon/apple-black.svg" alt="icon" />
                      <span>IOS App</span>
                    </a>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="/"
                      onClick={handleClick1}
                    >
                      <img src="images/icon/playstore.svg" alt="icon" />
                      <span>Android App</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* End right-widget */}
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <Link to="/">
            <img src="images/logo/teachme-logo.svg" alt="brand" />
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav  main-side-nav font-gordita"
          items={["home", "about", "benefits", "download"]}
          currentClassName="active"
          offset={-90}
        >
          <li className="nav-item">
            <a href="#home" className="nav-link" onClick={handleClick}>
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="#about" className="nav-link" onClick={handleClick}>
              About
            </a>
          </li>
          <li className="nav-item">
            <a href="#benefits" className="nav-link" onClick={handleClick}>
              Benefits
            </a>
          </li>

          <li className="nav-item">
            <a href="#faq" className="nav-link" onClick={handleClick}>
              FAQ
            </a>
          </li>

          <li className="nav-item">
            <a href="#download" className="nav-link" onClick={handleClick}>
              Download
            </a>
          </li>

        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default HeaderLandingMobileApp;
