import React from "react";

const FeatureContent = [
  {
    icon: "95",
    title: "Download App",
    subTitle: `You Can Download the TeachMe App for Android or iOS Device`,
    bgColor: "#d75b3d",
    delayAnimation: "",
  },
  {
    icon: "162",
    title: "Become Tutor",
    subTitle: `Register as a tutor by clicking on becoming a tutor icon.`,
    bgColor: "#e08a3f",
    delayAnimation: "200",
  },
  {
    icon: "165",
    title: "Verified Tutor",
    subTitle: `Become a verified tutor by clicking on verified tutor icon`,
    bgColor: "#99cfe4",
    delayAnimation: "400",
  },
];

const FancyFeatureTewentyThree = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <div className="block-style-twentyTwo">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>

              <img
                className="arrow-icon"
                src="images/icon/135.svg"
                alt="icon"
              />

          </div>
          {/* /.block-style-twentyTwo */}
        </div>
      ))}
    </>
  );
};

export default FancyFeatureTewentyThree;
